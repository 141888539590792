//Variables
$navbar-height: 11rem;
$stepper-height: 10rem;
$ctapopup-height: 10rem;

//Global presets
*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
  outline: none;
}
html {
  font-size: 10px; //font-size set to 10px (Browser default (100%) is 16px)
  scroll-behavior: smooth;
}
body {
  background-color: $color-bg;
  box-sizing: border-box;
}
button {
  cursor: pointer;
}
input[type="text"] {
  font-family: $font-primary;
  font-size: 1.6rem;
}

* {
  /* width */
  &::-webkit-scrollbar {
    width: 3px;
    margin-right: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 100px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $color-grey-4;
    border-radius: 100px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $color-grey-3;
  }
}